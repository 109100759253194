import { Button, Modal } from '@components';

type Props = {
  loading?: boolean;
};

export const AppConstructorSubmitButton: React.FC<Props> = ({ loading }) => {
  return (
    <Modal.Footer sticky className="-mt-10 px-0">
      <Button type="submit" loading={loading} className="px-10 capitalize">
        Install app
      </Button>
    </Modal.Footer>
  );
};
