import { Children, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Carousel from 'react-slick';
import axios, { AxiosError } from 'axios';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

import { AnyFunction, Organization } from '@typings';
import {
  API_ADMIN,
  CAROUSEL_SETTINGS,
  JWT_TOKEN,
  NO_ORGANIZATION,
  PLATFORM_API_URL,
} from '@constants';
import { createOrganization } from '@services';
import { setContext } from '@slices';
import { getConfig, getUserClusters } from '@thunks';
import { userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  debounce,
  formatModelName,
  formatToCamelCase,
  isObjectEmpty,
  isString,
  localStorage,
  noop,
  normalizeFormErrors,
  parseResponseError,
} from '@utils';

import { Button, Helmet, Icon } from '@components';

import { OnboardingInput } from './OnboardingInput';

const INITIAL_SLIDE_COUNT = 2;

const nameValidationSchema = {
  required: true,
  pattern: {
    value: /^[a-zA-Z0-9 -]*$/,
    message: 'Only letters, numbers, spaces, and hyphens (-) are allowed',
  },
};

const checkOrganizationExists = async (name: string) => {
  const headers = {};
  const token = localStorage.get<string>(JWT_TOKEN);

  const authorizationToken = token ? `Bearer ${token}` : undefined;

  if (authorizationToken) {
    // @ts-ignore
    headers.Authorization = authorizationToken;
  }

  return axios.get(`${PLATFORM_API_URL}${API_ADMIN}/orgs/${name}`, {
    headers,
  });
};

type Props = {
  onComplete?: AnyFunction;
};

export const OnboardingOrganization = ({ onComplete = noop }: Props) => {
  const dispatch = useDispatch();
  const { username } = useSelector(userSelector);

  const { register, handleSubmit, formState, setValue, setError, clearErrors } =
    useForm();

  const [createdOrganization, setOrganization] = useState<Organization.Model>();
  const [loading, setLoading] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideCount, setSlideCount] = useState(INITIAL_SLIDE_COUNT);
  const carouselRef = useRef<Carousel>(null);

  const slideClassName = '!flex h-[500px] flex-col justify-center';
  const errors = normalizeFormErrors<'name'>(formState.errors);
  const isErrorOccurred = !isObjectEmpty(errors);
  const isFirstSlide = slideIndex === 0;
  const isLastSlide = slideIndex === slideCount - 1;

  const handleSliderInitialization = () => {
    const slideCount = Children.count(carouselRef.current?.props.children);

    if (slideCount) {
      setSlideCount(slideCount);
    }
  };

  const handleSlideNext = useCallback(() => {
    carouselRef.current?.slickNext();
  }, []);

  const handleSlidePrev = useCallback(() => {
    carouselRef.current?.slickPrev();
  }, []);

  const handleNameError = useCallback(
    (message: string) => {
      setError('name', { message });
    },
    [setError],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceNameChange = useCallback(
    debounce(async (name: string) => {
      const formattedName = formatModelName(name);

      setLoading(true);

      try {
        await checkOrganizationExists(formattedName);

        handleNameError('Organization with such name already exits');
      } catch (error) {
        const status = (error as AxiosError)?.response?.status;

        if (status === 403) {
          handleNameError('Organization with such name already exits');
        }
      } finally {
        setLoading(false);
      }
    }, 500),
    [],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const formattedValue = formatModelName(value);

      if (formattedValue) {
        debounceNameChange(formattedValue);
      } else {
        debounceNameChange.clear();
      }

      clearErrors('name');
      setValue('name', value);
    },
    [clearErrors, setValue, debounceNameChange],
  );

  const handleOrgNameSubmit = handleSubmit(async ({ name }) => {
    if (!isObjectEmpty(errors)) {
      return;
    }

    const formattedName = formatModelName(name);

    setLoading(true);

    try {
      const organization = await createOrganization(formattedName);
      const formattedOrganization = formatToCamelCase(
        organization,
      ) as Organization.Model;

      setOrganization(formattedOrganization);
      handleSlideNext();
    } catch (error) {
      const parsedError = parseResponseError(error);
      const normalizedError = isString(parsedError)
        ? parsedError
        : parsedError.messages[0];

      handleNameError(normalizedError);
    } finally {
      setLoading(false);
    }
  });

  const handleCreationComplete = async () => {
    if (createdOrganization) {
      try {
        setLoading(true);

        const { clusters, adminUrl } = await dispatch(getConfig());
        const userClusters = await dispatch(
          getUserClusters(adminUrl, username),
        );
        const { name: createdOrganizationName } = createdOrganization;

        /**
         * User clusters which belongs to the created organization
         */
        const userCluster = userClusters.find(
          ({ orgName = NO_ORGANIZATION }) =>
            orgName === createdOrganizationName,
        );
        const cluster = clusters.find(
          ({ name }) => name === userCluster?.clusterName,
        );

        dispatch(
          setContext({
            organization: createdOrganization,
            cluster,
            project: null,
          }),
        );
        onComplete();
      } catch (error) {
        /**
         * Continue regardless error
         */
      } finally {
        setLoading(false);
      }
    } else {
      carouselRef.current?.slickGoTo(1);
    }
  };

  return (
    <>
      <Helmet
        title="Create Organization"
        description="Easily create your organization using our interactive sliding tool. Follow the guided steps to set up your organization and get started with seamless collaboration and management"
      />
      <div className="mr-32 flex flex-col gap-10">
        <Button
          variant="secondary"
          className="w-12 p-2"
          disabled={!!createdOrganization || isFirstSlide}
          onClick={handleSlidePrev}
        >
          <Icon icon={faChevronUp} />
        </Button>
        <Button
          disabled={!createdOrganization || isLastSlide}
          variant="secondary"
          className="w-12 p-2"
          onClick={handleSlideNext}
        >
          <Icon icon={faChevronDown} />
        </Button>
      </div>
      <Carousel
        {...CAROUSEL_SETTINGS}
        afterChange={setSlideIndex}
        onInit={handleSliderInitialization}
        ref={carouselRef}
      >
        <form className={slideClassName} onSubmit={handleOrgNameSubmit}>
          <h5 className="text-h5">Name your Organization:</h5>
          <OnboardingInput
            {...register('name', nameValidationSchema)}
            onChange={handleChange}
            error={errors.name}
          />
          <Button
            type="submit"
            className="mt-10 w-[148px]"
            loading={loading}
            disabled={isErrorOccurred}
          >
            Next
          </Button>
        </form>

        <div className={slideClassName}>
          <h3 className="text-h3">That&apos;s it 🥳</h3>
          <p className="mt-6 text-h6 font-500 text-neural-05">
            Now go and change the world! <br /> And don&apos;t forget to have
            fun 😉
          </p>
          <Button
            loading={loading}
            className="mt-10 w-[148px]"
            onClick={handleCreationComplete}
          >
            Let&apos;s do it!
          </Button>
        </div>
      </Carousel>
    </>
  );
};
