import { faRectangleTerminal } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { App, Job as JobType } from '@typings';
import { DEDICATED_APP_NAME } from '@constants';
import { as, path } from '@utils';
import { dedicatedApps } from '@content';

import { Button, Icon, Link, Render } from '@components';
import { Job } from '@components/Ui';

type Props = Pick<
  App.DedicatedModel,
  'appId' | 'appUrl' | 'targetName' | 'status'
> & {
  isUninstalling?: boolean;
  appName: string;
  onAppUninstall: (appName: string) => Promise<void>;
};

export const AppInstalledDedicatedItem = ({
  isUninstalling,
  appName,
  targetName,
  status,
  appId,
  appUrl,
  onAppUninstall,
}: Props) => {
  const { logo } = as.o<JobType.Item>(
    dedicatedApps.find(({ name }) => name === targetName),
  );
  return (
    <div className="relative">
      <div className="group peer relative z-20 flex min-h-[160px] gap-6 rounded-xl border border-transparent bg-white p-6 transition-colors hover:border-neural-02">
        <div className="flex h-14 w-14 shrink-0 items-center justify-center rounded-lg bg-rebecca p-2 text-[28px] text-white">
          <Render if={!logo}>
            <Icon
              icon={faRectangleTerminal}
              className="h-14 w-14 text-[36px]"
            />
          </Render>
          <Render if={logo}>
            <img
              src={logo}
              alt=""
              className={clsx({
                'w-full': targetName === DEDICATED_APP_NAME.STABLE_DIFFUSION,
              })}
            />
          </Render>
        </div>
        <div className="relative flex min-w-0 flex-1 flex-col gap-3">
          <div className="flex items-center gap-4">
            <p className="mr-auto truncate text-h6 text-black">{appName}</p>
            <p className="flex h-[22px] items-center rounded-2xl bg-primary-subtle px-2 text-footnote text-black">
              Installed
            </p>
          </div>
          <Render if={status}>
            <div className="flex flex-col gap-1">
              <p className="text-footnote capitalize text-neural-04">Status</p>
              <Job.Status status={status!} />
            </div>
          </Render>
          <div className="absolute inset-x-0 bottom-0 flex justify-end gap-3 bg-white opacity-0 transition-opacity before:absolute before:bottom-full before:h-full before:w-full before:bg-gradient-to-t before:from-white before:to-transparent group-hover:opacity-100">
            <Button
              variant="ghost-error"
              loading={isUninstalling}
              onClick={() => onAppUninstall(appName)}
            >
              Uninstall
            </Button>
            <Button disabled={!appId} className="flex p-0">
              <Link theme external blank to={path.app(appId)} className="px-4">
                Details
              </Link>
            </Button>
            <Render if={appUrl}>
              <Button className="flex p-0">
                <Link theme external blank to={appUrl!} className="px-4">
                  Open
                </Link>
              </Button>
            </Render>
          </div>
        </div>
      </div>
    </div>
  );
};
