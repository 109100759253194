import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { OutletContext } from '@typings';
import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import {
  AppCommand,
  formatAppName,
  normalizeFormErrors,
  path,
  transformNumberFormat,
} from '@utils';

import { Field, Theme } from '@components';
import {
  AppHuggingFaceTokenField,
  JobConstructorSection,
  JobPresetField,
} from '@components/Job';
import { AppConstructorSubmitButton } from '@components/Ui/Apps';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  presetName: z.string().min(1),
  hfToken: z.string().optional(),
  httpAuth: z.boolean().optional(),

  /**
   * LLM Inference config
   */
  llmAppName: z.string().min(1),
  llmTemperature: z.string().refine(
    (value) => {
      if (!value) {
        return true;
      }

      const formattedValue = transformNumberFormat(value);

      if (Number.isNaN(formattedValue)) {
        return false;
      }

      return (formattedValue as number) >= 0 && (formattedValue as number) <= 1;
    },
    { message: 'Enter a value from 0 to 1' },
  ),

  /**
   * PGVector config
   */
  pgVectorAppName: z.string().min(1),
  pgVectorUsername: z.string(),

  /**
   * Text Embeddings config
   */
  teiAppName: z.string().min(1),

  name: z.string(),
});

export const PrivateGptConstructorPage = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const {
    submitting,
    app: { name: appName },
    handleAppSubmit,
  } = useOutletContext<OutletContext.AppConstructor>();

  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { httpAuth: true },
  });

  const { register, formState, handleSubmit } = methods;
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleFormSubmit = handleSubmit(
    async ({
      name,
      presetName,
      hfToken,
      httpAuth,
      llmAppName,
      llmTemperature,
      pgVectorAppName,
      pgVectorUsername,
      teiAppName,
    }) => {
      try {
        const formattedName = formatAppName({ name, appName });
        const secretPath = path.create(
          clusterName,
          organizationName,
          projectName,
          hfToken,
          { prefix: '' },
        );
        const hfTokenSecret = hfToken ? `secret://${secretPath}` : null;

        const appCommand = new AppCommand();

        const command = appCommand
          .construct(
            `install https://github.com/neuro-inc/private-gpt ${appName} ${formattedName}`,
          )
          .set('preset_name', presetName)
          .set('http_auth', httpAuth)
          .set('llm_inference_app_name', llmAppName)
          .set('llm_temperature', llmTemperature)
          .set('pgvector_app_name', pgVectorAppName)
          .set('pgvector_user', pgVectorUsername)
          .set('text_embeddings_app_name', teiAppName)
          .set('huggingface_token_secret', hfTokenSecret)
          .compose({
            settingsParser: (key, value) => `--${key}=${value}`,
          });

        await handleAppSubmit({ name, command });
      } catch (error) {
        return error;
      }
    },
  );

  return (
    <FormProvider {...methods}>
      <form className="flex flex-1 justify-center" onSubmit={handleFormSubmit}>
        <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20">
          <JobConstructorSection name="resources">
            <JobPresetField
              note="Preset name used to run PrivateGPT web app"
              error={errors.presetName}
            />
          </JobConstructorSection>
          <JobConstructorSection name="secrets">
            <AppHuggingFaceTokenField note="HuggingFace token used to pull tokenizer into PrivateGPT app" />
          </JobConstructorSection>
          <JobConstructorSection name="llm">
            <Field.Input
              {...register('llmAppName')}
              required
              label="LLM Inference App Name"
              className="w-full"
              note="LLM Inference app name to integrate with"
              error={errors.llmAppName}
            />
            <Field.Input
              {...register('llmTemperature')}
              label="LLM Inference Temperature"
              className="w-full"
              note="Temperature parameter ('creativeness') for LLM. Less value - more strict penalty for going out of provided context. Enter a value from 0 to 1"
              error={errors.llmTemperature}
            />
          </JobConstructorSection>
          <JobConstructorSection name="pgvector">
            <Field.Input
              {...register('pgVectorAppName')}
              required
              label="PGVector App Name"
              className="w-full"
              note="PGVector app name to integrate with"
              error={errors.pgVectorAppName}
            />
            <Field.Input
              {...register('pgVectorUsername')}
              label="PGVector Username"
              className="w-full"
              note="Username in PGVector app to use while contacting PGVector"
              error={errors.pgVectorUsername}
            />
          </JobConstructorSection>
          <JobConstructorSection name="tei">
            <Field.Input
              {...register('teiAppName')}
              required
              label="Text Embeddings Inference App Name"
              className="w-full"
              note="Text Embeddings Inference app name to integrate with"
              error={errors.teiAppName}
            />
          </JobConstructorSection>
          <JobConstructorSection name="networking">
            <Field.Checkbox {...register('httpAuth')} error={errors.httpAuth}>
              HTTP authentication
            </Field.Checkbox>
          </JobConstructorSection>
          <JobConstructorSection name="metadata">
            <Field.Input
              {...register('name')}
              label="App Name"
              className="w-full"
              error={errors.name}
            />
          </JobConstructorSection>
          <AppConstructorSubmitButton loading={submitting} />
        </Theme.Container>
      </form>
    </FormProvider>
  );
};
