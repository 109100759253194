import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { OutletContext } from '@typings';
import { IS_LOCAL } from '@constants';
import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import {
  AppCommand,
  formatAppName,
  makeHuggingFaceSecretEnv,
  makeHuggingFaceSecretPath,
  normalizeFormErrors,
} from '@utils';

import { Field, Theme } from '@components';
import {
  AppConstructorArguments,
  AppHuggingFaceTokenField,
  JobConstructorSection,
  JobPresetField,
} from '@components/Job';
import { AppConstructorSubmitButton } from '@components/Ui/Apps';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  hfToken: z.string().optional(),
  presetName: z.string().min(1),
  modelName: z.string().min(1),
  tokenizerName: z.string().min(1),
  name: z.string(),
  ingressEnabled: z.boolean().optional(),
  args: z
    .object({
      value: z.string(),
    })
    .array(),
});

export const LlmInferenceConstructorPage = () => {
  const CHART = IS_LOCAL
    ? 'https://github.com/helm/examples.git'
    : 'https://github.com/neuro-inc/app-llm-inference.git';

  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      args: [],
    },
  });

  const {
    submitting,
    app: { name: appName },
    handleAppSubmit,
  } = useOutletContext<OutletContext.AppConstructor>();
  const { register, formState, handleSubmit } = methods;

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleAppInstall = handleSubmit(
    async ({
      name,
      modelName,
      tokenizerName,
      presetName,
      ingressEnabled,
      args,
      hfToken,
    }) => {
      try {
        const formattedName = formatAppName({ name, appName });
        const appCommand = new AppCommand();
        const hfTokenSecret = makeHuggingFaceSecretPath({
          organizationName,
          clusterName,
          projectName,
          token: hfToken,
        });
        const secretEnv = makeHuggingFaceSecretEnv(hfTokenSecret);

        args.forEach(({ value }) => {
          appCommand.arg(value);
        });

        const command = appCommand
          .construct(
            `install ${CHART} ${appName} ${formattedName} charts/llm-inference-app --timeout 15m0s`,
          )
          .set('preset_name', presetName)
          .set('ingress.enabled', ingressEnabled)
          .set('ingress.clusterName', clusterName!)
          .set('model.modelHFName', modelName)
          .set('model.tokenizerHFName', tokenizerName)
          .compose();

        await handleAppSubmit({ name, command, secretEnv });
      } catch (error) {
        return error;
      }
    },
  );

  return (
    <FormProvider {...methods}>
      <form className="flex flex-1 justify-center" onSubmit={handleAppInstall}>
        <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20 pb-6">
          <JobConstructorSection name="resources">
            <JobPresetField error={errors.presetName} />
          </JobConstructorSection>
          <JobConstructorSection name="configuration">
            <AppHuggingFaceTokenField />
            <Field.Input
              {...register('modelName')}
              required
              label="Model Name"
              className="w-full"
              error={errors.modelName}
            />
            <Field.Input
              {...register('tokenizerName')}
              required
              label="Tokenizer Name"
              className="w-full"
              error={errors.tokenizerName}
            />

            <Field.Checkbox {...register('ingressEnabled')}>
              Ingress Enabled
            </Field.Checkbox>

            <AppConstructorArguments />
          </JobConstructorSection>
          <JobConstructorSection name="metadata">
            <Field.Input
              {...register('name')}
              label="Name"
              className="w-full"
              note="App name"
              error={errors.name}
            />
          </JobConstructorSection>
          <AppConstructorSubmitButton loading={submitting} />
        </Theme.Container>
      </form>
    </FormProvider>
  );
};
