import { customAlphabet } from 'nanoid';
import { lowercase } from 'nanoid-dictionary';

import { formatModelName } from '@utils/formatters';

type Params = {
  name?: string;
  appName: string;
};

export const formatAppName = ({ name, appName }: Params) =>
  name ? formatModelName(name) : `${appName}-${customAlphabet(lowercase, 8)()}`;
