import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { AnyObject, Cluster, DateFormat, Mode } from './shared';

export namespace JobVolume {
  export type Base = {
    readOnly: boolean;
    dstPath: string;
  };

  export type Storage = Base & {
    srcStorageUri: string;
  };

  export type Disk = Base & {
    srcDiskUri: string;
  };

  export type Secret = Omit<Base, 'read_only'> & {
    srcSecretUri: string;
  };
}

export namespace App {
  export enum Status {
    Succeeded = 'succeeded',
    Failed = 'failed',
    Installing = 'installing',
  }

  export type Item = {
    image: string | null;
    installed: boolean;
    tags: string[];
    pinned: boolean;
  };

  export type BuiltInItem = {
    icon: IconProp;
    name: string;
    path: string;
    tags?: string[];
    description?: string;
  };

  export type DedicatedModel = {
    name: string;
    status: Status;
    appId: string;
    appUrl?: string;
    targetName: string;
  };
}

export namespace Job {
  export type Status =
    | 'cancelled'
    | 'failed'
    | 'pending'
    | 'running'
    | 'succeeded'
    | 'suspended';

  export enum Tab {
    Overview = 'details',
    Observability = 'observability',
    Log = 'logs',
    Outputs = 'outputs',
  }

  export type TransitionStatus = {
    description: string | null;
    reason: string | null;
    status: Status;
    transitionTime: DateFormat;
  };

  export type VolumeDef = {
    storagePath: string;
    jobPath: string;
    mode: Mode;
    editable: boolean;
    nonRemovable?: boolean;
    createDiskIfMissing?: boolean;
    defaultDiskSizeGB?: number;
  };

  export type ContainerResources = {
    cpu: number;
    memory: number;
    memoryMb: number;
    shm: boolean;
    gpu?: number;
    gpuModel?: string;
  } & Cluster.Gpu;

  export type Container = {
    image: string;
    resources: ContainerResources;
    command?: string;
    entrypoint?: string;
    env: AnyObject;
    secretEnv: AnyObject;
    volumes?: JobVolume.Storage[];
    diskVolumes?: JobVolume.Disk[];
    secretVolumes?: JobVolume.Secret[];
    http: {
      port: number;
      requiresAuth: boolean;
    };
    tty?: boolean;
  };

  export type History = {
    status: Status;
    description: string | null;
    createdAt: DateFormat;
  };

  export type Running = Model & {
    targetName: string;
  };

  export type Item = {
    title?: string;
    description?: string;
    name: string;
    logo?: string;
    image: string;
    tags?: string[];
    httpPort?: number | null;
    httpAuth?: boolean;
    env?: Record<string, string>;
    jobResourcePreset?: string;
    shm?: boolean;
    defaultVolumes?: VolumeDef[];
    editableVolumes?: boolean;
    editableSecrets?: boolean;
    jobLifeSpan?: number | null;
    command?: string;
    group?: string;
    entryPoint?: string;
    mlflowIntegration?: boolean;
    tty?: boolean;
    workingDir?: string;
  };

  export type LiveModel = {
    id: string;
    tags: string[];
  };

  export type Model = {
    clusterName: string;
    container: Container;
    description?: string;
    history: History;
    httpUrl: string;
    httpUrlNamed?: string;
    id: string;
    internalHostname: string;
    internalHostnameNamed?: string;
    name: string;
    orgName?: string;
    passConfig: boolean;
    owner: string;
    presetName: string;
    projectName: string;
    status: Status;
    statuses: TransitionStatus[];
    tags?: string[];
    uri: string;

    /**
     * Quick added for job rerun
     *
     * todo: refactor properties
     */
    restartPolicy: string;
    privileged: boolean;
    priority: string;
    scheduleTimeout?: number;
    maxRunTimeMinutes?: number;
  };
}
