import { useState } from 'react';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';

import { cn, isString } from '@utils';

import { Icon, Popover } from '@components';

type Props = {
  copy?: boolean | string;
  className?: string;
  title?: string;
  text?: React.ReactNode;
  children?: React.ReactNode;
};

export const JobDetailsItem = ({
  className,
  copy,
  title,
  text,
  children,
}: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (open: boolean) => {
    if (open) {
      const contentToCopy = isString(copy) ? copy : (text as string);
      navigator.clipboard.writeText(contentToCopy);

      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    } else {
      setCopied(false);
    }
  };

  const renderContent = () => {
    if (!text && !children) {
      return null;
    }

    if (copy) {
      return (
        <Popover
          open={copied}
          onOpenChange={handleCopy}
          triggerClassName="w-fit text-left"
        >
          <p
            slot="trigger"
            className="inline-flex w-fit items-center gap-2 transition-colors hover:text-black"
          >
            {text ?? children}
            <Icon icon={faCopy} className="text-[18px]" />
          </p>
          <p>Copied</p>
        </Popover>
      );
    }

    return <p className="w-fit">{text ?? children}</p>;
  };

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <h6 className="text-footnote text-neural-04">{title}</h6>
      {renderContent()}
    </div>
  );
};
